<template>
  <div class="h-backgroud-color_body">
    <van-sticky>
      <van-search v-model="name" background="#fff" placeholder="请输入姓名/车架号/车牌号" @search="onSearch"> </van-search>
    </van-sticky>
    <van-tabs v-model="active" sticky offset-top="54px">
      <van-tab title="全部"><OrderList ref="orderListRef0" type="" :name="name"/></van-tab>
      <van-tab title="审核中"><OrderList ref="orderListRef1" type="2" :name="name"/></van-tab>
      <van-tab title="待确认"><OrderList ref="orderListRef2" type="5" :name="name"/></van-tab>
      <van-tab title="审核退回"><OrderList ref="orderListRef3" type="3" :name="name"/></van-tab>
      <van-tab title="拒绝"><OrderList ref="orderListRef4" type="4" :name="name"/></van-tab>
      <van-tab title="已撤销"><OrderList ref="orderListRef5" type="9" :name="name"/></van-tab>
    </van-tabs>
  </div>
</template>
<script>
import OrderList from "../components/MoreOrderList";
import dayjs from "dayjs";
export default {
  name: "ProductOrderList",
  components: { OrderList },
  provide() {
    return {
      pageContext: this
    };
  },
  data() {
    return {
      active: 0,
      name: ""
    };
  },
  watch: {
    active: function() {
      this.onSearch();
    }
  },
  methods: {
    dayjs,
    onSearch() {
      if (!this.$refs[`orderListRef${this.active}`]) {
        return false;
      }
      this.$refs[`orderListRef${this.active}`].onSearch();
    }
  }
};
</script>
<style lang="scss" scoped></style>
